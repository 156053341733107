$c1: #5a398a;
$c2: #93c249;
$text: #888;
$danger: #d34c42;
$info: #005cd0;
$success: #54c761;

$c1_light: #8464c7;
$font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

@import '../../assets/scss/main.scss';